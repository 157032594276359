import React from "react";
import '../scss/sunset.scss'
import bubble from '../assets/sunset-bubble.png'; // Tell webpack this JS file uses this image
import pinpoint from '../assets/pin.svg';

export default function Sunset() {
    return (
        <div className="sunset-container">
            <div className="sunset-bg"></div>
            <div className="sunset-bg-mobile"></div>
            <div className="sunset-title">
                <div className="sun-title">Sunset Party</div>
                <div className="sun-date">21 Août 2023</div>
            </div>
            <div className="bottom-part">
                <div className="blue-ocean" />
                <img src={bubble} alt="Bubble" className='sun-bubble' />
                <div className="island-art"><img className="pinpoint" src={pinpoint} alt="" /> Vouliagmeni, 17h</div>
            </div>
        </div>
    )
}