import React, { useState } from "react";
import axios from "axios";
import "../scss/userAdd.scss";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import contactGif from "../assets/contact.GIF"
import cross from "../assets/cross.svg"

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80vw',
  maxWidth: '600px',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
export default class UsersAdd extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      cityHall: false,
      preWedding: false,
      wedding: false,
      sentence: "",
      users: [],
    };
  }

  addUser = (event) => {
    this.setState((prevState) => ({
      users: [...prevState.users, { firstName: "", lastName: "" }],
    }));
  };

  deleteUser = (event) => {
    let users = [...this.state.users];

    users.splice(event.target.dataset.id, 1);
    this.setState({ users });
  };

  handleChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    if (name.includes("firstName-") || name.includes("lastName-")) {
      let users = [...this.state.users];
      users[event.target.dataset.id][
        name.replace("-" + event.target.dataset.id, "")
      ] = value;
      this.setState({ users });
    } else {
      this.setState({
        [name]: value,
      });
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();

    let users = [
      {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        cityHall: this.state.cityHall,
        preWedding: this.state.preWedding,
        wedding: this.state.wedding,
        sentence: this.state.sentence,
      },
    ];

    this.state.users.forEach((user) => {
      users.push({
        firstName: user.firstName,
        lastName: user.lastName,
        email: this.state.email,
        cityHall: this.state.cityHall,
        preWedding: this.state.preWedding,
        wedding: this.state.wedding,
        sentence: this.state.sentence,
      });
    });
    axios.post(`https://api.clara-mika.fr/users`, { users }).then((data) => {
      console.log(data);
      if (data.status === 200) {
        toast("Votre participation a bien été enregistrée ❤️", {
          autoClose: 3000
        });
      } else {
        toast.error("Désolé une erreur est survenu 😥");
      }
      this.setState({
        firstName: "",
        lastName: "",
        email: "",
        cityHall: false,
        preWedding: false,
        wedding: false,
        sentence: "",
        users: [],
      });
    });
  };

  render() {
    return (
      <div>
        <ToastContainer position="unset" />
        <form onSubmit={this.handleSubmit}>
          <div className="flex-form">
            <div className="crud-container">
              <h2 className="title">Je confirme ma présence</h2>
              <hr />
              <div className="input-flex first">
                <div>
                  <label>Nom :</label>
                  <input
                    type="text"
                    name="lastName"
                    value={this.state.lastName}
                    onChange={this.handleChange}
                  />
                </div>
                <div>
                  <label>Prénom(s) :</label>
                  <input
                    type="text"
                    name="firstName"
                    value={this.state.firstName}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="input-flex">
                <label>Adresse e-mail :</label>
                <input
                  type="text"
                  name="email"
                  value={this.state.email}
                  onChange={this.handleChange}
                />
              </div>
              <div className="input-flex">
                <label>Sera présent(e) à :</label>

                {/* <div class="checkbox-container">
                  <input class="checkbox-input" id="apples" type="checkbox" />
                  <label class="checkbox" for="apples">
                    <span>
                      <svg width="12px" height="10px">
                        <use xlinkHref={`#check`}></use>

                      </svg>
                    </span>
                    <span>Apples</span>
                  </label>
                  <input class="checkbox-input" id="oranges" type="checkbox" />
                  <label class="checkbox" for="oranges">
                    <span>
                      <svg width="12px" height="10px">
                        <use xlinkHref={`#check`}></use>

                      </svg>
                    </span>
                    <span>Oranges</span>
                  </label>
                </div>
 */}

                <div className="checkboxes">
                  <div className="checkbox-option">
                    <label className="check-label">La Mairie 20&nbsp;/&nbsp;07</label>
                    <input
                      type="checkbox"
                      name="cityHall"
                      checked={this.state.cityHall}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="checkbox-option">
                    <label className="check-label">La Sunset Party 21&nbsp;/&nbsp;08</label>
                    <input
                      type="checkbox"
                      name="preWedding"
                      checked={this.state.preWedding}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="checkbox-option">
                    <label className="check-label">La Houppa 22&nbsp;/&nbsp;08</label>
                    <input
                      type="checkbox"
                      name="wedding"
                      checked={this.state.wedding}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>

              {this.state.users.map((val, i) => {
                let firstNameId = "firstName-" + i;
                let lastNameId = "lastName-" + i;

                return (
                  <div className="input-flex first">
                    <div>
                      <label>Nom :</label>
                      <input
                        type="text"
                        name={lastNameId}
                        data-id={i}
                        value={this.state.users[i].lastName}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div>
                      <label>Prénom(s) :</label>
                      <input
                        type="text"
                        name={firstNameId}
                        data-id={i}
                        value={this.state.users[i].firstName}
                        onChange={this.handleChange}
                      />
                    </div>
                    <button className="delete-usr" onClick={this.deleteUser}>
                      <img src={cross} alt="croix" />
                    </button>
                  </div>
                );
              })}

              <button type="button" className="add-guest" onClick={this.addUser}>
                + Ajouter un(e) autre participant(e)
              </button>

              <label>Un petit mot ?</label>
              <textarea
                type="text"
                name="sentence"
                value={this.state.sentence}
                onChange={this.handleChange}
              ></textarea>

              <button type="submit" className="add-user">
                Envoyer la réponse
              </button>
            </div>
            <div className="photo-contact">
              <img className="ending-img" src={contactGif} alt="" width="100%" />
              <div className="contact-details">
                <span>Clara : +33 6 23 18 84 44</span>
                <span>Michaël : +33 7 62 87 34 74</span>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}
