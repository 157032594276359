import React from "react";
import '../scss/hotels.scss'
import hotel1 from "../assets/hotel-1.webp"
import hotel2 from "../assets/hotel-2.webp"
import hotel3 from "../assets/hotel-3.jpg"
import complet from "../assets/tampon-complet.png"
import hamburger from "../assets/hamburger.svg"
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80vw',
    maxWidth: '600px',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function Hotels() {

    const [openAthenian, setOpenAthenian] = React.useState(false);
    const handleOpenAthenian = () => setOpenAthenian(true);
    const handleCloseAthenian = () => setOpenAthenian(false);

    const [openAzur, setOpenAzur] = React.useState(false);
    const handleOpenAzur = () => setOpenAzur(true);
    const handleCloseAzur = () => setOpenAzur(false);

    const [openAmarilla, setOpenAmarilla] = React.useState(false);
    const handleOpenAmarilla = () => setOpenAmarilla(true);
    const handleCloseAmarilla = () => setOpenAmarilla(false);

    return (
        <div className="hotel-container">
            {/* <div className="hotel-title">Les Hôtels</div> */}
            <div className="hotels">
                <div className="hotel-group">
                    <img src={hotel1} alt="" className="hotel-img" />
                    <div className="text-group">
                        <span className="hotel-name">Athenian Riviera Hotel</span>
                        <span className="hotel-desc">A partir de 175€/nuit</span>
                        <span className="promo">Code Promo : <strong>"C&M"</strong></span>
                        <button className="details" onClick={handleOpenAthenian}>Plus de détails</button>
                        <div className="details-desktop">
                            C’est l’hôtel des jeunes! Là où seront les mariés.
                            <br />Des navettes partiront de cet hôtel.
                            <br /><br />
                            <strong>Il reste des chambres !</strong>
                            <br />
                            Contactez Michaël ou Clara pour procéder à la réservation
                        </div>
                        <a rel="noreferrer noopener" href="https://athenianrivierahotel.reserve-online.net/?checkin=2023-08-19&rooms=1&nights=3&adults=2" target="_blank"><button className="hotel-btn">Je réserve !</button></a>
                    </div>
                </div>
                <div className="hotel-group">
                    <img src={hotel2} alt="" className="hotel-img odd" />
                    <div className="text-group">
                        <span className="hotel-name">Azur Hotel</span>
                        <span className="hotel-desc">A partir de 300€/nuit</span>
                        <span className="promo">Code Promo : <strong>"Clara&MichaelAug23"</strong></span>
                        <button className="details" onClick={handleOpenAzur}>Plus de détails</button>
                        <div className="details-desktop">
                            Littéralement collé à l’Athenian.
                            <br />Des navettes partiront de cet hôtel.
                            <br /><br />
                            Cliquer sur le bouton <img src={hamburger} alt="" className="hamburger" /> en haut à droite sur « Booking code / Code de reservation»
                            et rentrer le code promo. 3 nuits minimum pour profiter du code promo.
                        </div>
                        <a rel="noreferrer noopener" href="https://azurhotel.reserve-online.net/" target="_blank"><button className="hotel-btn">Je réserve !</button></a>
                    </div>
                </div>
                {/* <div className="hotel-group">
                    <img src={hotel3} alt="" className="hotel-img" />
                    <img src={complet} alt="" className="hotel-img-complet" />
                    <div className="text-group">
                        <span className="hotel-name">Amarilia Hotel</span>
                        <span className="hotel-desc">A partir de 150€/nuit</span>
                        <span className="promo">Code Promo : <strong>"CLARAMICHAEL"</strong></span>
                        <button className="details" onClick={handleOpenAmarilla}>Plus de détails</button>
                        <div className="details-desktop">
                            Cet hôtel est a 6 min en voiture des 2 premiers hôtels.
                            <br />Des navettes partiront de cet hôtel.
                            <br /><br />
                            Cliquer sur le bouton <img src={hamburger} alt="" className="hamburger" /> en haut à droite sur « Booking code / Code de reservation »
                            et rentrer le code promo. 3 nuits minimum pour profiter du code promo.
                        </div>
                        <a rel="noreferrer noopener" href="https://amarilia.reserve-online.net/" target="_blank"><button className="hotel-btn">Je réserve !</button></a>
                    </div>
                </div> */}
            </div>
            <Modal
                open={openAthenian}
                onClose={handleCloseAthenian}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" sx={{ fontFamily: "JDWave", fontSize: 25, fontWeight: "Bold", mt: 2 }}>
                        Athenian Riviera Hotel
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ fontFamily: "JDWave", fontSize: 20, mt: 2 }}>
                        C’est l’hôtel des jeunes! Là où seront les mariés.
                        <br />Des navettes partiront de cet hôtel.
                        <br /><br />
                        <strong>Il reste des chambres !</strong>
                        <br />
                        Contactez Michaël ou Clara pour procéder à la réservation
                    </Typography>
                    <Button onClick={handleCloseAthenian}>Fermer</Button>
                </Box>
            </Modal>
            <Modal
                open={openAzur}
                onClose={handleCloseAzur}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" sx={{ fontFamily: "JDWave", fontSize: 25, fontWeight: "Bold", mt: 2 }}>
                        Azur Hotel
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ fontFamily: "JDWave", fontSize: 20, mt: 2 }}>
                        Littéralement collé à l’Athenian.
                        <br />Des navettes partiront de cet hôtel.
                        <br /><br />
                        Cliquer sur le bouton <img src={hamburger} alt="" className="hamburger" /> en haut à droite sur « Booking code / Code de reservation » et rentrer le code promo. 3 nuits minimum pour profiter du code promo.
                    </Typography>
                    <Button onClick={handleCloseAzur}>Fermer</Button>
                </Box>
            </Modal>
            {/* <Modal
                    open={openAmarilla}
                    onClose={handleCloseAmarilla}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" sx={{ fontFamily: "JDWave", fontSize: 25, fontWeight: "Bold", mt: 2 }}>
                            Amarilia Hotel
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ fontFamily: "JDWave", fontSize: 20, mt: 2 }}>
                            Cet hôtel est a 6 min en voiture des 2 premiers hôtels.
                            <br />Des navettes partiront de cet hôtel.
                            <br /><br />
                            Cliquer sur le bouton <img src={hamburger} alt="" className="hamburger" /> en haut à droite sur « Booking code / Code de reservation »
                            et rentrer le code promo. 3 nuits minimum pour profiter du code promo.
                        </Typography>
                        <Button onClick={handleCloseAmarilla}>Fermer</Button>
                    </Box>
                </Modal> */}
        </div>
    )
}