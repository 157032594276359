import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import absent from "../assets/absent.png"
import present from "../assets/present.png"
import "../scss/userList.scss"

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#066EB9',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default class UsersList extends React.Component {
  state = {
    users: [],
    firstName: true,
    lastName: true,
    email: true,
    date: true
  }

  getCityHallPresent() {
    let i = 0;
    this.state.users.forEach(user => {
      if (user.cityHall === true)
        i = i + 1;
    });
    return i;
  }

  getWeddingPresent() {
    let i = 0;
    this.state.users.forEach(user => {
      if (user.wedding === true)
        i = i + 1;
    });
    return i;
  }

  getPreWeddingPresent() {
    let i = 0;
    this.state.users.forEach(user => {
      if (user.preWedding === true)
        i = i + 1;
    });
    return i;
  }

  dateOptions = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };

  cityHallPresent = this.state.users.map(user => { user.cityHall = true ? ++this.cityHallPresent : 0 });
  preWeddingPresent = 0;
  weddingPresent = 0;

  componentDidMount() {
    axios.get(`https://api.clara-mika.fr/users`)
      .then(res => {
        const users = res.data;
        let sortedUsers = users.sort((a, b) => {
          return new Date(b.date) - new Date(a.date);
        });
        this.setState({ users: sortedUsers });
      })
  }

  handleSortByFirstname = () => {
    let sortedUsers;
    if (this.state.firstName) {
      sortedUsers = this.state.users.sort((a, b) => {
        if (a.firstName < b.firstName) return -1;
        if (a.firstName > b.firstName) return 1;
        return 0;
      });
    } else {
      sortedUsers = this.state.users.sort((a, b) => {
        if (a.firstName < b.firstName) return 1;
        if (a.firstName > b.firstName) return -1;
        return 0;
      });
    }
    this.setState({ users: sortedUsers, firstName: !this.state.firstName });
  }

  handleSortByName = () => {
    let sortedUsers;
    if (this.state.lastName) {
      sortedUsers = this.state.users.sort((a, b) => {
        if (a.lastName < b.lastName) return -1;
        if (a.lastName > b.lastName) return 1;
        return 0;
      });
    } else {
      sortedUsers = this.state.users.sort((a, b) => {
        if (a.lastName < b.lastName) return 1;
        if (a.lastName > b.lastName) return -1;
        return 0;
      });
    }
    this.setState({ users: sortedUsers, lastName: !this.state.lastName });
  }

  handleSortByEmail = () => {
    let sortedUsers;
    if (this.state.email) {
      sortedUsers = this.state.users.sort((a, b) => {
        if (a.email < b.email) return -1;
        if (a.email > b.email) return 1;
        return 0;
      });
    } else {
      sortedUsers = this.state.users.sort((a, b) => {
        if (a.email < b.email) return 1;
        if (a.email > b.email) return -1;
        return 0;
      });
    }
    this.setState({ users: sortedUsers, email: !this.state.email });
  }

  handleSortByDate = () => {
    let sortedUsers;
    if (this.state.date) {
      sortedUsers = this.state.users.sort((a, b) => {
        if (a.date < b.date) return -1;
        if (a.date > b.date) return 1;
        return 0;
      });
    } else {
      sortedUsers = this.state.users.sort((a, b) => {
        if (a.date < b.date) return 1;
        if (a.date > b.date) return -1;
        return 0;
      });
    }
    this.setState({ users: sortedUsers, date: !this.state.date });
  }

  render() {
    return (
      <div>
        <div className='dashboard'>
          <div className='stat-box'>
            <span className='stat-title'>Présents à la Mairie</span>
            <span className='stat-value'>{this.getCityHallPresent()}</span>
          </div>
          <div className='stat-box'>
            <span className='stat-title'>Présents à la Sunset</span>
            <span className='stat-value'>{this.getPreWeddingPresent()}</span>
          </div>
          <div className='stat-box'>
            <span className='stat-title'>Présents à la Houppa</span>
            <span className='stat-value'>{this.getWeddingPresent()}</span>
          </div>
        </div>
        <a href="https://api.clara-mika.fr/users/excel"><button className='excel-btn'>Exporter au format Excel</button></a>

        <TableContainer component={Paper} >
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell align='center' onClick={this.handleSortByFirstname}>Prénom</StyledTableCell>
                <StyledTableCell align='center' onClick={this.handleSortByName}>Nom</StyledTableCell>
                <StyledTableCell align='center' onClick={this.handleSortByEmail}>Adresse Email</StyledTableCell>
                <StyledTableCell align='center'>Mairie</StyledTableCell>
                <StyledTableCell align='center'>Sunset</StyledTableCell>
                <StyledTableCell align='center'>Houppa</StyledTableCell>
                <StyledTableCell align='center'>Message</StyledTableCell>
                <StyledTableCell align='center' onClick={this.handleSortByDate}>Date</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.users.map((user) => (
                <StyledTableRow
                  key={user.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align='center'>{user.firstName}</TableCell>
                  <TableCell align='center' component="th" scope="user">{user.lastName}</TableCell>
                  <TableCell align='center'>{user.email}</TableCell>
                  <TableCell align='center'>{user.cityHall === true ? <img src={present} alt="Logo" className='present-icon icon' /> : <img src={absent} alt="Logo" className='absent-icon icon' />}</TableCell>
                  <TableCell align='center'>{user.preWedding === true ? <img src={present} alt="Logo" className='present-icon icon' /> : <img src={absent} alt="Logo" className='absent-icon icon' />}</TableCell>
                  <TableCell align='center'>{user.wedding === true ? <img src={present} alt="Logo" className='present-icon icon' /> : <img src={absent} alt="Logo" className='absent-icon icon' />}</TableCell>
                  <TableCell align='center'>{user.sentence}</TableCell>
                  <TableCell align='center'>{new Date(user.date).toLocaleDateString('fr-FR', this.dateOptions)}</TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }
}