import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Users from './pages/users';
import Stats from './pages/statistics';
import ProgrammeRedirect from './pages/programme';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <div className='content'>
        <Routes>
          <Route exact path='/' element={<App />} />
          {/* <Route path='/guestlist' element={<Users />} /> */}
          <Route path='/dashboard' element={<Stats />} />
          <Route path='/programme' element={<ProgrammeRedirect />} />
        </Routes>
      </div>
    </Router>
  </React.StrictMode>
);

reportWebVitals();
