import '../scss/history.scss'
import React from "react";
import pola1 from '../assets/polaroids/Pola-1.png';
import pola2 from '../assets/polaroids/Pola-2.png';
import pola3 from '../assets/polaroids/Pola-3.png';
import pola4 from '../assets/polaroids/Pola-4.png';
import pola5 from '../assets/polaroids/Pola-5.png';
import pola6 from '../assets/polaroids/Pola-6.png';
import pola7 from '../assets/polaroids/Pola-7.png';
import pola8 from '../assets/polaroids/Pola-8.png';
import pola9 from '../assets/polaroids/Pola-9.png';
import pola10 from '../assets/polaroids/Pola-11.png';
import logoOrange from '../assets/logo-orange.svg';
import frise from '../assets/frise2.png';
import arrow from '../assets/arrowBlue.svg'
import intersect from '../assets/Intersect.png'

export default function History() {
    /*  const scroll = (scrollOffset) => {
         useRef.current.scrollLeft += scrollOffset;
     }; */
    return (
        <div className="history-container">
            <span className='history-title'>Un peu de nous...</span>
            {/* <div className='orange-sun'></div> */}
            <img src={intersect} alt="" className='intersect-sun' />
            <img src={frise} alt="frise" className='entire-frise' />
            <div className='entire-frise'>
                <img src={arrow} alt="" className='blue-arrow blink-fast' />
                <div className='pola-and-stick'>
                    {/* <span className="desc d1">2014 - La rencontre</span> */}
                    <img /* onClick={() => scroll(100)} */ src={pola1} alt="Polaroid" className='pola pola-1' />
                </div>
                <div className='pola-and-stick'>
                    <img src={pola2} alt="Polaroid" className='pola' />
                    {/* <span className="desc d2">2016 - Premières vacances</span> */}
                </div>
                <div className='pola-and-stick'>
                    {/* <span className="desc d3">2016 - New York</span> */}
                    <img src={pola3} alt="Polaroid" className='pola' />
                </div>
                <div className='pola-and-stick'>
                    <img src={pola4} alt="Polaroid" className='pola' />
                    {/* <span className="desc d4">2017 - Échange au Mexique</span> */}
                </div>
                <div className='pola-and-stick'>
                    {/* <span className="desc d5">2017 - Échange au Brésil</span> */}
                    <img src={pola5} alt="Polaroid" className='pola' />
                </div>
                <div className='pola-and-stick'>
                    <img src={pola6} alt="Polaroid" className='pola' />
                    {/* <span className="desc d6">2018 - Remise des diplômes</span> */}
                </div>
                <div className='pola-and-stick'>
                    {/* <span className="desc d7">2019 - Un bel été</span> */}
                    <img src={pola7} alt="Polaroid" className='pola' />
                </div>
                <div className='pola-and-stick'>
                    <img src={pola8} alt="Polaroid" className='pola' />
                    {/* <span className="desc d8">2021 - Toujours plus de voyages</span> */}
                </div>
                <div className='pola-and-stick'>
                    {/* <span className="desc d9">2022 - La demande</span> */}
                    <img src={pola9} alt="Polaroid" className='pola' />
                </div>
                <div className='pola-and-stick'>
                    <img src={pola10} alt="Polaroid" className='pola' />
                    {/* <span className="desc d10">20/07/2023 - To be continued . . .</span> */}
                </div>
            </div>
            <div>
                <img className='orange-logo' src={logoOrange} alt="" />
            </div>
        </div>
    );
}