import React from 'react';
import "../scss/scroll.scss"
import Home from '../pages/home'
import History from '../pages/history';
import Rsvp from '../pages/rsvp';
import WeddingList from '../pages/weddingList';
import Mairie from '../pages/mairie';
import Sunset from '../pages/sunset';
import Houppa from '../pages/houppa';
import ReactFullpage from '@fullpage/react-fullpage';
import Hotels from '../pages/hotels';
const SEL = "custom-section";
const SECTION_SEL = `.${SEL}`;

// NOTE: if using fullpage extensions/plugins put them here and pass it as props.
const pluginWrapper = () => {
    /*
     * require('./fullpage.fadingEffect.min'); // Optional. Required when using the "fadingEffect" extension.
     */
};

export default class ScrollFeature extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fullpages: [
                {
                    text: "Section 1"
                },
                {
                    text: "Section 2"
                },
                {
                    text: "Section 3"
                }
            ]
        };
    }

    render() {
        return (
            <div className="App">
                <ReactFullpage
                    /* debug  *//* Debug logging */
                    // Required when using extensions
                    pluginWrapper={pluginWrapper}
                    // fullpage options
                    licenseKey="KF0X6-UWSQJ-JL7XH-26XHK-ZMCOL" // Get one from https://alvarotrigo.com/fullPage/pricing/
                    navigation={true}
                    showActiveTooltip={true}
                    animateAnchor={true}
                    scrollingSpeed={1000}
                    credits={true}
                    touchSensitivity={15}
                    /* scrollOverflow={true} */
                    /* responsiveWidth={592} */
                    navigationTooltips={["Accueil", "La Mairie", "Sunset Party", "La Houppa", "Infos et Résas", "Un peu de nous", "RSVP & Contact"]}
                    anchors={["home", "mairie", "sunset", "houppa", "hotels", "histoire", "rsvp", "wedding-list"]}
                    sectionSelector={SECTION_SEL}
                    render={(comp) => (
                        <ReactFullpage.Wrapper>
                            <div className={SEL}>
                                <Home className="slide" />
                            </div >
                            <div className={SEL}>
                                <Mairie className="slide" />
                            </div >
                            <div className={SEL}>
                                <Sunset className="slide" />
                            </div >
                            <div className={SEL}>
                                <Houppa className="slide" />
                            </div >
                            <div className={SEL}>
                                <Hotels className="slide" />
                            </div >
                            <div className={SEL}>
                                <History className="slide" />
                            </div >
                            <div className={SEL}>
                                <Rsvp className="slide" />
                            </div >
                            <div className={SEL}>
                                <WeddingList className="slide" />
                            </div >
                        </ReactFullpage.Wrapper>
                    )}
                />
            </div>
        );
    }
    /* render() {
        return (
            <div>
                <Home />
                <Mairie />
                <Sunset />
                <Houppa />
                <Hotels />
                <History />
                <Rsvp />
            </div >
        )
    } */
}