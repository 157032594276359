import * as React from 'react';
import '../scss/header.scss';
import { useState } from 'react';
import endWave from '../assets/ending-wave.png'
import endWaveLg from '../assets/ending-wave-lg.png'
const ResponsiveAppBar = () => {
    const [isShown, setIsShown] = useState(false);
    const handleClick = event => {
        // 👇️ toggle shown state
        setIsShown(current => !current);
    };
    return (
        <div>
            <div className='header'>
                {!isShown && <button className='burger-button' onClick={handleClick}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" /></svg></button>}
                {!isShown && <a href='#rsvp'><button className='rsvp-btn'>RSVP</button></a>}
            </div>
            <div className={`main-menu ${isShown ? "active" : "none"}`}>
                <svg onClick={handleClick} className='close-btn' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" /></svg>
                <div className='button-list'>
                    <a href='#home'><button className='btn btn-1' onClick={handleClick}>Home</button></a>
                    <a href='#mairie'><button className='btn btn-2' onClick={handleClick}>La Mairie</button></a>
                    <a href='#sunset'><button className='btn btn-3' onClick={handleClick}>Sunset Party</button></a>
                    <a href='#houppa'><button className='btn btn-4' onClick={handleClick}>Houppa</button></a>
                    <a href='#hotels'><button className='btn btn-5' onClick={handleClick}>Infos et Résas</button></a>
                    <a href='#histoire'><button className='btn btn-6' onClick={handleClick}>Un peu de nous</button></a>
                    <a href='#rsvp'><button className='btn btn-7' onClick={handleClick}>RSVP</button></a>
                    <a href='#rsvp'><button className='btn btn-8' onClick={handleClick}>Contact</button></a>
                    <a href='#wedding-list'><button className='btn btn-8' onClick={handleClick}>Liste de mariage</button></a>
                </div >
                <img src={endWave} alt="Menu" className='nav-menu mobile' />
                <img src={endWaveLg} alt="Menu" className='nav-menu desktop' />
            </div >
        </div >
    );
};

export default ResponsiveAppBar;