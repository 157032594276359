import React, { useEffect } from 'react';

const ProgrammeRedirect = () => {
    useEffect(() => {
        window.location.href = 'https://wethenew.notion.site/Welcome-to-Vouliagmeni-3aef4dbe8120413db7b7566541beb5e0';
    }, []);

    return null;
};

export default ProgrammeRedirect;
