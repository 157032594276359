import * as React from 'react';
import "../scss/statistics.scss"
import axios from 'axios';
import sunOK from "../assets/sun-OK.png"
import sunKO from "../assets/sun-KO.png"
import mairieOK from "../assets/mairie-OK.png"
import mairieKO from "../assets/mairie-KO.png"
import ringOK from "../assets/ring-OK.png"
import ringKO from "../assets/ring-KO.png"
import { PieChart, Pie, Sector, Cell, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import ring from "../assets/RING.png"
import sun from "../assets/SUN.png"
import mairie from "../assets/MAIRIE.png"
import Modal from '@mui/material/Modal';

export default class Stats extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            data: [],
            users: [],
            firstName: true,
            lastName: true,
            email: true,
            date: true
        };
    }

    dateOptions = {
        day: '2-digit',
        month: 'short',
        hour: '2-digit',
        minute: '2-digit'
    };

    generateData() {
        const startDate = new Date(2022, 11, 11);  // 1er décembre 2022
        const endDate = new Date();  // Date courante
        const numDays = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24));  // Nombre de jours entre startDate et endDate
        const data = [];

        for (let i = 0; i < numDays; i++) {
            const date = new Date(startDate);
            date.setDate(date.getDate() + i);
            const dateString = date.toLocaleDateString();  // Obtenir la date au format "dd/mm/yyyy"

            data.push({
                name: dateString,  // Utiliser la date comme nom de l'axe des abscisses
                uv: 2 + i,
            });
        }

        this.setState({ data });
    }

    dataWedding = [
        /* {
            name: 'Présents',
            value: this.getWeddingPresent()
        },
        {
            name: 'Absents',
            value: this.getTotalResponse - this.getWeddingPresent()
        } */
        {
            name: 'Présents',
            value: 39
        },
        {
            name: 'Absents',
            value: 3
        }
    ]

    data = [
        {
            name: '18/12',
            uv: 2,
        },
        {
            name: '19/12',
            uv: 24,
        },
        {
            name: '20/12',
            uv: 32,
        },
        {
            name: '21/12',
            uv: 36,
        },
        {
            name: '22/12',
            uv: 36,
        },
        {
            name: '23/12',
            uv: 39,
        },
        {
            name: '24/12',
            uv: 41,
        },
    ];

    componentDidMount() {
        axios.get(`https://api.clara-mika.fr/users`)
            .then(res => {
                const users = res.data;
                let sortedUsers = users.sort((a, b) => {
                    return new Date(b.date) - new Date(a.date);
                });
                this.setState({ users: sortedUsers });
            })
        /* this.generateData(); */
    }

    getTotalResponse() {
        let i = 0;
        this.state.users.forEach(user => {
            i = i + 1;
        });
        return i;
    }

    getCityHallPresent() {
        let i = 0;
        this.state.users.forEach(user => {
            if (user.cityHall === true)
                i = i + 1;
        });
        return i;
    }

    getPreWeddingPresent() {
        let i = 0;
        this.state.users.forEach(user => {
            if (user.preWedding === true)
                i = i + 1;
        });
        return i;
    }

    getWeddingPresent() {
        let i = 0;
        this.state.users.forEach(user => {
            if (user.wedding === true)
                i = i + 1;
        });
        return i;
    }

    handleSortByFirstname = () => {
        let sortedUsers;
        if (this.state.firstName) {
            sortedUsers = this.state.users.sort((a, b) => {
                if (a.firstName < b.firstName) return -1;
                if (a.firstName > b.firstName) return 1;
                return 0;
            });
        } else {
            sortedUsers = this.state.users.sort((a, b) => {
                if (a.firstName < b.firstName) return 1;
                if (a.firstName > b.firstName) return -1;
                return 0;
            });
        }
        this.setState({ users: sortedUsers, firstName: !this.state.firstName });
    }

    handleSortByName = () => {
        let sortedUsers;
        if (this.state.lastName) {
            sortedUsers = this.state.users.sort((a, b) => {
                if (a.lastName < b.lastName) return -1;
                if (a.lastName > b.lastName) return 1;
                return 0;
            });
        } else {
            sortedUsers = this.state.users.sort((a, b) => {
                if (a.lastName < b.lastName) return 1;
                if (a.lastName > b.lastName) return -1;
                return 0;
            });
        }
        this.setState({ users: sortedUsers, lastName: !this.state.lastName });
    }

    handleSortByEmail = () => {
        let sortedUsers;
        if (this.state.email) {
            sortedUsers = this.state.users.sort((a, b) => {
                if (a.email < b.email) return -1;
                if (a.email > b.email) return 1;
                return 0;
            });
        } else {
            sortedUsers = this.state.users.sort((a, b) => {
                if (a.email < b.email) return 1;
                if (a.email > b.email) return -1;
                return 0;
            });
        }
        this.setState({ users: sortedUsers, email: !this.state.email });
    }

    handleSortByDate = () => {
        let sortedUsers;
        if (this.state.date) {
            sortedUsers = this.state.users.sort((a, b) => {
                if (a.date < b.date) return -1;
                if (a.date > b.date) return 1;
                return 0;
            });
        } else {
            sortedUsers = this.state.users.sort((a, b) => {
                if (a.date < b.date) return 1;
                if (a.date > b.date) return -1;
                return 0;
            });
        }
        this.setState({ users: sortedUsers, date: !this.state.date });
    }

    COLORS = ['#70bd6a', '#b93d3d'];

    fetchData = async () => {
        const response = await fetch('https://api.clara-mika.fr/users/duplicate');
        const json = await response.json();
        console.log(json);  // Ajout d'un log console ici
        this.setState({
            data: json,
            isOpen: true,
        }, () => console.log(this.state));  // Ajout d'un log console ici pour vérifier l'état après la mise à jour
    };


    render() {
        const { isOpen, data } = this.state;
        return (
            <div className="stats-container">
                <span className='page-title'>Dashboard</span>
                <div className='stats-flex'>
                    <div className='graph-1'>
                        <ResponsiveContainer width="100%" height="100%">
                            <AreaChart
                                width={500}
                                height={400}
                                /* data={this.state.data} */
                                data={this.data}
                                margin={{
                                    top: 10,
                                    right: 30,
                                    left: 0,
                                    bottom: 0,
                                }}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Area type="monotone" dataKey="uv" stroke="#8884d8" fill="#8884d8" />
                            </AreaChart>
                        </ResponsiveContainer>
                    </div>
                    {/* <div className='doughnuts'>
                        <div className='dough-1'>
                            <PieChart width={300} height={175} onMouseEnter={this.onPieEnter}>
                                <Pie
                                    data={this.dataWedding}
                                    innerRadius={60}
                                    outerRadius={80}
                                    fill="#8884d8"
                                    paddingAngle={5}
                                    dataKey="value"
                                >
                                    {this.dataWedding.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={this.COLORS[index % this.COLORS.length]} />
                                    ))}
                                </Pie>
                            </PieChart>
                        </div>
                        <div className='dough-2'></div>
                    </div> */}
                </div>
                <div className='calculated-stats'>
                    <div className='stat-box'>
                        <span className='stat-title'>Invités ayant répondu</span>
                        <span className='stat-value'>{this.getTotalResponse()}</span>
                    </div>
                    <div className='stat-box'>
                        <span className='stat-title'>Présents à la Mairie</span>
                        <span className='stat-value'>{this.getCityHallPresent()}</span>
                        <img src={mairie} alt="" className='mairie' />
                    </div>
                    <div className='stat-box'>
                        <span className='stat-title'>Présents à la Sunset</span>
                        <span className='stat-value'>{this.getPreWeddingPresent()}</span>
                        <img src={sun} alt="" className='sunn' />
                    </div>
                    <div className='stat-box'>
                        <span className='stat-title'>Présents à la Houppa</span>
                        <span className='stat-value'>{this.getWeddingPresent()}</span>
                        <img src={ring} alt="" className='ring' />
                    </div>
                </div>

                <div className='flex-buttons'>
                    <a href="https://api.clara-mika.fr/users/excel"><button className='excel-btn'>Exporter au format Excel</button></a>
                    <button className='doublon-btn' onClick={this.fetchData}>Vérifier les doublons</button>
                </div>
                <div>
                    <Modal
                        open={isOpen}
                        onClose={() => this.setState({ isOpen: false })}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <div style={{
                            backgroundColor: 'white',
                            borderRadius: '8px',
                            padding: '20px',
                            maxWidth: '500px',
                            width: '100%',
                            boxShadow: '0 3px 5px rgba(0, 0, 0, 0.2)',
                            textAlign: 'center',
                        }}>
                            {data.length > 0 ? (
                                data.map((item, index) => (
                                    <p key={index} style={{ color: '#333', fontSize: '18px' }}>
                                        {item.firstName} {item.lastName}
                                    </p>
                                ))
                            ) : (
                                <p style={{ color: '#333', fontSize: '18px' }}>Aucun doublon dans la liste :)</p>
                            )}
                        </div>
                    </Modal>
                </div>
                <div className='guest-table-mobile'>
                    {this.state.users.map((user, index) => (
                        <div className='blockk'>
                            <span><strong>{user.firstName} {user.lastName}</strong></span>
                            <br />
                            <span>{user.email}</span>
                            <br />
                            <br />
                            <span className='center-elem'>{user.cityHall === true ? <img src={mairieOK} alt="Logo" className='present-icon icon' /> : <img src={mairieKO} alt="Logo" className='absent-icon icon' />}</span>
                            <span className='center-elem'>{user.preWedding === true ? <img src={sunOK} alt="Logo" className='present-icon icon' /> : <img src={sunKO} alt="Logo" className='absent-icon icon' />}</span>
                            <span className='center-elem'>{user.wedding === true ? <img src={ringOK} alt="Logo" className='present-icon icon' /> : <img src={ringKO} alt="Logo" className='absent-icon icon' />}</span>
                            <br />
                            <br />
                            <span>{new Date(user.date).toLocaleDateString('fr-FR', this.dateOptions)}</span>
                        </div>
                    ))}
                </div>
                <div className='guest-table'>
                    <table>
                        <thead>
                            <tr>
                                <th onClick={this.handleSortByFirstname}>Prénom</th>
                                <th onClick={this.handleSortByName}>Nom</th>
                                <th onClick={this.handleSortByEmail}>Adresse Email</th>
                                <th>Mairie</th>
                                <th>Sunset</th>
                                <th>Houppa</th>
                                <th>Message</th>
                                <th onClick={this.handleSortByDate}>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.users.map((user, index) => (
                                <tr key={user.id} className={index % 2 ? 'stripe' : ''}>
                                    <td>{user.firstName}</td>
                                    <td>{user.lastName}</td>
                                    <td>{user.email}</td>
                                    <td className='center-elem'>{user.cityHall === true ? <img src={mairieOK} alt="Logo" className='present-icon icon' /> : <img src={mairieKO} alt="Logo" className='absent-icon icon' />}</td>
                                    <td className='center-elem'>{user.preWedding === true ? <img src={sunOK} alt="Logo" className='present-icon icon' /> : <img src={sunKO} alt="Logo" className='absent-icon icon' />}</td>
                                    <td className='center-elem'>{user.wedding === true ? <img src={ringOK} alt="Logo" className='present-icon icon' /> : <img src={ringKO} alt="Logo" className='absent-icon icon' />}</td>
                                    <td>{user.sentence}</td>
                                    <td>{new Date(user.date).toLocaleDateString('fr-FR', this.dateOptions)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}