import React from "react";
import '../scss/weddingList.scss'
import shush from '../assets/shush.png'
import who from '../assets/who.png'
import gift from '../assets/gift.jpg'
import arrowEnd from "../assets/arrowEnd2.svg"
import logo from '../assets/logo-orangeCM.svg'
import clara from '../assets/clara-mika-end.jpeg'

const WeddingList = () => {
    return (
        <>
            <main className="wedding-list-page">
                <section className="gifts">
                    <h1>La liste de mariage</h1>
                    <p className="description-gift">Notre plus beau cadeau est de vous avoir près de nous, mais si vous souhaitez nous témoignez davantage, une liste de mariage est disponible ici&nbsp;:</p>
                    <a target="_blank" href="https://www.millemercismariage.com/clara-mika/liste.html" className="btn-contribute"><button>Contribuer à la liste</button></a>
                </section>
                <img src={clara} alt="" className="round" />
                <div className="end-logos">
                    <img src={logo} alt="" className="orange-cm" />
                    <a href="#home">
                        <img src={arrowEnd} alt="arrow" className="arrow-end fade" />
                    </a>
                </div>
            </main>
        </>
    );
};

export default WeddingList;