import React from "react";
import UsersAdd from "../components/UsersAdd";
import '../scss/rsvp.scss';
import arrow from "../assets/arrow2.svg"

export default function Rsvp() {
    return (
        <div className="rsvp-container">
            <div className="form">
                <UsersAdd />
            </div>
            <br />

            <a href='#wedding-list' className='arrowblock'>
                <div className='bounce'>
                    <p>Liste de mariage</p>
                    <img src={arrow} alt="arrow" className='arrow ' />
                </div>
            </a>
        </div>
    )
}