import React from 'react';
import '../scss/countdown.scss';

const DateTimeDisplay = ({ value, type, isDanger }) => {
  return (
    <div className={isDanger ? 'countdown danger' : 'countdown'}>
      <span className="time-period">{value}</span>
      <span className="text-days">{type}</span>
    </div>
  );
};

export default DateTimeDisplay;