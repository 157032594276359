import './App.css';
import ScrollFeature from './components/Scroll';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from './components/header'

function App() {
  return (
    <div className="App">
      <ScrollFeature></ScrollFeature>
      <Header />
    </div>
  );
}

export default App;
