import React from 'react';
import CountdownTimer from '../components/CountdownTimer';
import '../scss/home.scss'
import coverLogo from '../assets/logo-cover-page.svg'; // Tell webpack this JS file uses this image
import greekBuilding from '../assets/greek-buildings.svg'; // Tell webpack this JS file uses this image
import desktopBuilding from "../assets/greek-desktop.svg"
import arrow from "../assets/arrow.svg"
/* import { Link, animateScroll as scroll } from 'react-scroll';
 */
export default function Home() {
    const weedingDate = new Date('August 22, 2023 16:00:00').getTime();

    return (
        <div className="home-container">
            <div className='flex-home-container'>
                <img src={coverLogo} alt="Logo" className='logo-cover' />
                <div className="timer-block mobile">
                    <span className="date-full">Athènes</span>
                    <span className="date-full">22 Août 2023</span>
                    {<CountdownTimer targetDate={weedingDate} />}
                </div>
                <img src={greekBuilding} alt="Building" className='greek-building' />
            </div>
            <img src={desktopBuilding} alt="Logo" className='logo-desktop' />
            <div className="timer-block desktop">
                <span className="date-full">Athènes</span>
                <span className="date-full">22 Août 2023</span>
                {<CountdownTimer targetDate={weedingDate} />}
            </div>
            <a href='#mairie' className='arrowblock'>
                <div className='bounce'>
                    <span>Scroll</span>
                    <img src={arrow} alt="arrow" className='arrow ' />
                </div>
            </a>
        </div>
    );
}