import React from "react";
import '../scss/mairie.scss'
import background from '../assets/backgrounds/mairie.jpg'; // Tell webpack this JS file uses this image
import backgroundDesktop from '../assets/backgrounds/Mairie_desktop2.png'; // Tell webpack this JS file uses this image
import mairieWaves from '../assets/backgrounds/mairie-waves.png'; // Tell webpack this JS file uses this image
import pinpoint from '../assets/pin.svg';

export default function Mairie() {
    return (
        <div className="mairie-container">
            <img src={background} alt="Background" className='background-mairie-mobile' />
            <img src={mairieWaves} alt="BackgroundDesktop" className='mairie-waves' />
            <img src={backgroundDesktop} alt="BackgroundDesktop" className='background-mairie' />
            <div className="mairie-title">La Mairie</div>
            <div className="mairie-date">20 Juillet 2023</div>
            <div className="mairie-loc-hour">
                <div className="mairie-location"><img className="pinpoint" src={pinpoint} alt="" /> 16e Arrondissement</div>
                <div className="mairie-hour">Paris, 15h15</div>
            </div>
        </div>
    )
}