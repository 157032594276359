import React from "react";
import '../scss/houppa.scss'
/* import houppa from '../assets/houppa.png'; // Tell webpack this JS file uses this image
 */import houppa from '../assets/houppa3.png'; // Tell webpack this JS file uses this image
import houpwave from '../assets/houppa-wave.png'; // Tell webpack this JS file uses this image
import pinpoint from '../assets/pin.svg'; // Tell webpack this JS file uses this image
/* import wave1 from '../assets/animated1.svg'; // Tell webpack this JS file uses this image
import wave2 from '../assets/animated2.svg'; // Tell webpack this JS file uses this image */

export default function Houppa() {
    return (
        <div className="houppa-container">
            {/*  <div className="waveanim">
                <img className="wave1" src={wave1} alt="" />
                <img className="wave2" src={wave1} alt="" />
            </div> */}
            {/*  <div className="waveanim2">
                <img className="wave1" src={wave2} alt="" />
                <img className="wave2" src={wave2} alt="" />
            </div> */}
            <img className="houppa-wave" src={houpwave} alt="" />
            <div className="houppa-title">La Houppa</div>
            <div className="houppa-date">22 Août 2023</div>
            <div className="houppa-location"><img className="pinpoint" src={pinpoint} alt="" /> Vouliagmeni, 17h</div>
            <img src={houppa} alt="Logo" className='houppa-img' />
        </div>
    )
}